import { ApolloClient } from '@apollo/client';
import { GetUserDocument, GetUserQuery } from './graphql/generated';

declare const GQL_CLIENT: ApolloClient<any>;

export function bootstrapIntercom(history) {
    GQL_CLIENT.watchQuery<GetUserQuery>({
        query: GetUserDocument,
    }).subscribe((result) => {
        if (!result.loading && result.data) {
            let user = result.data.user;
            if (user) {
                window.Intercom('update', {
                    email: user.email,
                    name: user.name,
                    user_id: user._id,
                });
            }
        }
    });
    history.listen(() => {
        if (window.Intercom && typeof window.Intercom === 'function') {
            window.Intercom('update');
        }
    });
}

export function openIntercom() {
    const launcherElement = window.document.getElementsByClassName('intercom-lightweight-app-launcher');
    if (!launcherElement || !launcherElement[0]) return;

    (launcherElement[0] as any).click();
}
