import { MarketplaceCredentials } from 'src/services/marketplace/MarketplaceService';
import { FormData } from './ConnectAlzaForm';
import { ConnectMarketplaceBloc } from '../ConnectMarketplaceBloc';

export class ConnectAlzaStepBloc extends ConnectMarketplaceBloc<FormData> {
    public getName(): string {
        return 'ConnectAlzaStepBloc';
    }

    protected getRequestData(formData: FormData): MarketplaceCredentials {
        return {
            serviceScope: this.region,
            apiBaseUrl: 'https://externalservicesmanagement.alza.cz',
            secretKey: formData.secretKey,
            supplierBranchId: formData.supplierBranchId,
            supplierId: formData.supplierId,
            buyerId: '94', // TODO MSL discuss with miky to move this to company-types as default value for buyerId ?
        };
    }

    protected getFormData(data: MarketplaceCredentials): FormData {
        return {
            supplierId: data?.supplierId ?? '',
            supplierBranchId: data?.supplierBranchId ?? '',
            secretKey: data?.secretKey ?? '',
        };
    }
}
